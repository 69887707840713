<template>
  <div class="header_box clearfix">
    <div class="logo_box" @click="logoClick">
      <img :src="logoImg" title="回到首页">
    </div>
    <el-popover class="user_info" popper-class="head-propper-box" placement="bottom-start" width="100" trigger="hover">
      <div slot="reference">
        <span class="span user_name">{{userInfo.nickName || userInfo.userName || userInfo.phone}}</span>
        <el-avatar class="user_head_img" :src="userInfo.headImg"></el-avatar>
      </div>
      <div class="popover_box">
        <div class="operate" @click="$router.push({name: 'UserHome'})"><i class="el-icon-user-solid"></i>我的信息</div>
        <div class="operate" @click="$router.push({name: 'EditPwd'})"><i class="el-icon-edit"></i>修改密码</div>
        <el-divider></el-divider>
        <div class="operate" @click="logout"><i class="el-icon-switch-button"></i>退出登录</div>
      </div>
    </el-popover>
  </div>
</template>

<script>

export default {
  name: 'Header',
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    companyInfo () {
      return this.$store.state.companyInfo
    },
    equipInfo () {
      return this.$store.state.equipInfo
    },
    logoImg () {
      if (this.companyInfo.logoUrl) return this.companyInfo.logoUrl
      return 'https://media.meayan.com/fa1bea58380e4cc97e6a566d9fbbb236.png'
    }
  },
  watch: {
    userInfo (data) {
      if (data.simplePwd) {
        this.$message.warning('您的密码过于简单，请进行修改。')
        this.$router.push({ name: 'EditPwd' })
      }
    }
  },
  methods: {
    async logout () {
      const response = await this.$axios.post('/login/logout')
      if (response.code === '0000') {
        this.$router.push({ name: 'Login' })
      }
    },
    logoClick () {
      if (this.equipInfo.id) {
        this.$router.push('/SelectEquip')
      } else {
        this.$router.push('/SelectCompany')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header_box {
  padding-right: 20px;
  background: #FFF;
  .logo_box {
    float: left;
    width: 188px;
    height: 66px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user_info {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    .user_name {
      font-size: 16px;
      font-weight: bold;
      margin-right: 5px;
    }
    .span {
      display: inline-block;
      height: 48px;
      line-height: 48px;
      vertical-align: middle;
      @include text-hidden;
      max-width: 84px;
    }
    .head_img {
      width: 48px;
      border-radius: 50px;
      margin-right: 10px;
    }
    .user_head_img {
      vertical-align: middle;
    }
  }
}
.popover_box {
  padding: 4px 0;
  .operate {
    line-height: 30px;
    font-size: 12px;
    cursor: pointer;
    padding: 0 16px;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
    i {
      margin-right: 6px;
    }
  }
  ::v-deep .el-divider--horizontal {
    margin: 2px 0;
  }
}
</style>
